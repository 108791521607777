
import React from  'react';
import { MultiSelect } from "react-multi-select-component";


class MultiselectDropDown extends React.Component {

    state = {
        selected:[]
    }

    componentDidMount() {
        if(this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    getValue = () =>{
        let item = '';
        this.state.selected.forEach((val,index)=>{
            if(index+1===this.state.selected.length) {
                item+=val.value
            } else {
                item+=val.value+';';
            }
        })
        return item;
    }

    setValue = (values) =>{
        if(values)  this.setState({selected: values});
    }

    render() {
        return(
            <>
                 <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label>

                <MultiSelect
                    options={this.props.options}
                    value={this.state.selected}
                    onChange={(selected)=>{this.setState({selected})}}
                    labelledBy={this.props.title}
                />
            </>
        )
    }
}

export {MultiselectDropDown}