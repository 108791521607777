import React from 'react'
import Props from "prop-types";
import axios from "axios";
import {showToast} from '../../fToast/ToastCallback';

class FloatFile extends React.Component {

	_onChange = () => {
		let file = this.file.files[0];
		const fileSize = this.file.files[0].size / 1024 / 1024; // in MiB


		if (fileSize > 50) {
			showToast({
				type: 'error',
				message: 'File size not be greater than 50 mb'
			})
			return
		} else {

			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				if(this.props.onLoading) {
					this.props.onLoading(true);
				}
				axios({
					method: 'post',
					url: 'https://bifrost.eka.io/file',
					data: formData,
					headers: {
						clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
						clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
					}
				})
					.then(res => {
						this.setState({
							...this.state,
							file: res.data.url
						})
						if(this.props.onLoading) {
							this.props.onLoading(false);
						}
					})
					.catch(()=>{
						if(this.props.onLoading) {
							this.props.onLoading(false);
						}
					})
			}
		}
	}

	componentDidMount() {
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	value = () => {
		return this.state.file;
	}

	getValue = () => {
		return this.state.file;
	}

	state = {
		file: undefined
	}

	setValue = (file) => {
		this.setState({
			file
		})
	}

	render() {
		return (
			<div className="full-width" style={this.props.style}>
				{
					this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label> : null
				}
				<div style={this.props.style} className={`floating-label ${this.state.className}`}>
					<input type="file" ref={ref => {
						this.file = ref;
					}} onChange={this._onChange} {...this.props.inputProps}
						accept={this.props.accept}
						style={this.props.inputStyle} />
				</div>
				{
					this.props.blue ?
						<p style={{
							fontSize: 9,
							color: "#EE3F5a",
							margin: 0
						}}>{this.props.blue}</p> : null
				}
			</div>
		)
	}

}


FloatFile.propTypes = {
	title: Props.string,
}

FloatFile.defaultProps = {
	required: true,
	inputProps: {},
	accept:".jpg,.jpeg,.pdf,.png,.xlsx,.csv,.eml"
}

export {
	FloatFile
}
