import React, {useEffect, useRef, useState} from 'react'
import Props from "prop-types";
import axios from "axios";
import {showToast} from '../fToast/ToastCallback';

function FunctionalFile(props) {

	let FILE = React.createRef();
	let [url,setUrl] = useState()
	let _onChange = () => {
		let file = FILE.current.files[0];
		const fileSize = FILE.current.files[0].size / 1024 / 1024; // in MiB
		if (fileSize > 50) {
			showToast({
				type: 'error',
				message: 'File size not be greater than 50 mb'
			})
		} else {
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				if(props.onLoading) {
					props.onLoading(true);
				}
	
				axios({
					method: 'post',
					url: 'https://bifrost.eka.io/file',
					data: formData,
					headers: {
						clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
						clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
					}
				})
					.then(res => {
						setUrl(res.data.url)
						if(props.onLoading) {
							props.onLoading(false);
						}
					})
					.catch(()=>{
						if(props.onLoading) {
							props.onLoading(false);
						}
					})
			}
		}
	}
	useEffect(()=>{
		if(props.handleRef){
			props.handleRef({
				getValue:()=>url,
				setValue:(url)=>{
					setUrl(url);
					FILE.current.value=url;
				}
			})
		}
	},[url])
	return (
		<div className="full-width" style={props.style}>
			{
				props.title ? <label className="input-label" style={props.labelStyle}>
					{
						props.title
					}:
				</label> : null
			}
			<div style={props.style} className={`input`}>
				<input type="file" ref={FILE} onChange={_onChange} {...props.inputProps}
					   accept=".jpg,.jpeg,.pdf,.png,.xlsx,.csv,.eml"
					   style={props.inputStyle} />
			</div>
			{
				props.blue ?
					<p style={{
						fontSize: 9,
						color: "#EE3F5a",
						margin: 0
					}}>{props.blue}</p> : null
			}
		</div>
	)
}

const FileUpload = (props)=> {

	let Input_ID = `attachment_Input${Math.random()}`
	let uploadBtn = useRef(), file_ref = useRef();
	const [File, setFile] = useState('');

	useEffect(() => {
		if (props.handleRef) {
			props.handleRef(file_ref.current);
		}
		if(props.fileurl){
			setFile(props.fileurl)
		}
	}, [props]);

	let fileName = "", fileExt = "";

	const fetchDimension = () => {
		if(props.dimension){
			let file = file_ref.current.files[0];
			if (file) {
				let Extensions = ['.jpg','.jpeg','.png'];
				let FLAG = false;
				Extensions.forEach(ext => {
					if(file.name.toLowerCase().endsWith(ext) && FLAG===false){
						FLAG = true;
					}
				});
				if(!FLAG){
					showToast({
						type: 'error',
						message: `Please select a proper file format`
					})
					return;
				}
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function (e) {
					var image = new Image();
					image.src = e.target.result;
					image.onload = function () {
						var height = this.height;
						var width = this.width;
						if(width>props.dimension[0] || height>props.dimension[1]){
							showToast({
								type: 'error',
								message: "Please select file of proper dimension"
							})
							return
						}
						_upload();
					};
				};
			}else{
				showToast({
					type: 'error',
					message: 'Please select a file'
				})
			}
		}else{
			_upload()
		}
	}

	const _upload = () => {

		let file = file_ref.current.files[0];
		if (file) {
			if(props.typeCheck){
				let Extensions = ['.jpg','.jpeg','.png'];
				let FLAG = false;
				Extensions.forEach(ext => {
					if(file.name.toLowerCase().endsWith(ext) && FLAG===false){
						FLAG = true;
					}
				});
				if(!FLAG){
					showToast({
						type: 'error',
						message: `Please select a proper file format`
					})
					return;
				}
			}
			if(props.size){
				let size = file_ref.current.files[0].size;
				if(size>props.size){
					showToast({
						type: 'error',
						message: `Please select a file less than ${props.size/1000}KB`
					})
					return;
				}
			}
			uploadBtn.current.innerHTML = "<div class='mini-loader'></div>";
			const formData = new FormData();
			formData.append('file', file);

			if(props.onLoading) {
				props.onLoading(true);
			}

			axios({
				method: 'post',
				url: 'https://file.eka.io/file',
				data: formData,
				headers: {
					clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
					clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
				}
			}).then(res => {
				setFile(res.data.url);
				let data = {
					name: fileName,
					file: res.data.url,
					type: fileExt
				};

				props.onUpload(data);
				if(props.onLoading) {
					props.onLoading(false);
				}
				uploadBtn.current.innerHTML = props.type==='Mono'?"":"<svg style={{ verticalAlign: 'middle'}} className='bi bi-plus-circle' xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'></path><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'></path></svg>"
			}).catch(()=>{
				if(props.onLoading) {
					props.onLoading(false)
				}
			})

		} else {
			showToast({
				type: 'error',
				message: 'Please select a file'
			})
		}
	};

	if(props.readOnly) {
		return <></>
	}
	return (
		<>
			<input onChange={fetchDimension} id={Input_ID} ref={ref => file_ref.current = ref} type="file" style={{display: 'none'}} required={true}/>
			
			<label ref={uploadBtn} htmlFor={Input_ID} style={{width: '70px', height: '70px', fontSize: "24px", color: '#666', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '7px', cursor: 'pointer', borderRadius: '15px', border: '1px dashed #666',backgroundImage: `url('${props.type==='Mono'?File:''}')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
				<svg style={{ verticalAlign: "middle"}} className="bi bi-plus-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
					<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
				</svg>
			</label>
		</>
	)
}

FunctionalFile.propTypes = {
	title: Props.string,
}

FunctionalFile.defaultProps = {
	required: true,
	inputProps: {}
}

export {
	FunctionalFile, FileUpload
}
