import React, { useRef, useState } from 'react';
import Moment from 'moment';
import { useEffect } from "react";
import axios, { get, post } from "../../../Model/Network/Config/Axios";
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';
import { showToast } from '../fToast/ToastCallback';
import Portal from '../Portal';
import Text from './Text';
import attachment from './attachment.png';
import { Modal } from '../fModal/Modal';


const Index = (props) => {

	console.log(props);

    let {id} = props;
    let MESSAGE = useRef(),file_ref=useRef();
    let [rooms,setRooms] = useState([]);
	const [interval,setInterval] = useState(undefined);
	const [modal,setModal] = useState(undefined);
	const [stateLoading,setStateLoading]  = useState(false);


    useEffect(()=>{
		get(`workspace/seekClarification/getRoomMessages/${id}`,(e,r)=>{
            if(r) {
                setRooms(r.messages.reverse());
				setInterval(setTimeout(INIT,5000))
            }
        })
		return () => {
			if(interval){
				clearTimeout(interval)
			}
		}
    },[])

    useEffect(()=>{
        scrollToBottom();
    },[rooms])

    let INIT = () => {
        get(`workspace/seekClarification/getRoomMessages/${id}`,(e,r)=>{
            if(r) {
                setRooms(r.messages.reverse());
				setInterval(setTimeout(INIT,5000))
            }
        })
    }

    let handleKeyPress = (e) => {
		if (e.key === 'Enter') {
            let message = MESSAGE.current.value;
            if(!message) {
                showToast({
                    type:'error',
                    message:"Please enter the message"
                })
                return;
            }
            post(`workspace/seekClarification/sendMessage/${id}`,{message,file:''},(e,r)=>{
                if(r) {
                    INIT(id);
                    MESSAGE.current.value=''
                }
            })
		}
	}

    let scrollToBottom = () => {
		if(!props.closed)MESSAGE.current.scrollIntoView({behavior: "smooth"});
	}


    let mapItem = (item,index) => {
        let length = rooms.length;
		let hidden = false;
		let date = true;
		if (length > 2 && index > 1) {
			hidden = rooms[index].sender && rooms[index].sender.id === rooms[index - 1].sender.id;
		}
		if (length > 1 && index > 1 && index < length) {
			let date1 = Moment(rooms[index - 1].createdAt).format('YYYY MM DD');
			let date2 = Moment(rooms[index].createdAt).format('YYYY MM DD');
			let one = Moment(date1).isSame(date2, 'day')
			date = !one;
		} else if (index === 0) {
			date = false
		}
        return(
             <Text key={index} data={item} hidden={hidden} date={date}/>
        )
    }

	let _onChange = () => {
		let file = file_ref.files[0];
		const fileSize = file_ref.files[0].size / 1024 / 1024; // in MiB

		if (fileSize > 50) {
			showToast({
				type: 'error',
				message: 'File size not be greater than 50 mb'
			})
		} else {
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				setStateLoading(true);
	
				axios({
					method: 'post',
					url: 'https://bifrost.eka.io/file',
					data: formData,
					headers: {
						clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
						clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
					}
				})
					.then(res => {

						post(`workspace/seekClarification/sendMessage/${id}`,{message:file.name,file:res.data.url},(e,r)=>{
							if(r) {
								INIT(id);
								MESSAGE.current.value=''
								setStateLoading(false);
								setModal(false);
							}
						})
					})
					.catch(()=>{
						
					})
			}
		}
	}


  return(
    <Portal>
         <div style={{
			position:'fixed',
			zIndex:1110,
			top:0,
			bottom:0,
			left:0,
			backgroundColor:"rgba(1,1,1,.1)"
		}}>
            <div style={{
				position:'fixed',
				zIndex:1111,
				boxShadow:"10px 0 20px 0 rgba(123,147,180,.2)",
				...props.right?{right:0}:{left:0},
				top:0,
				bottom:0,
				width:500,
				backgroundColor:'var(--white-color)'
			}}>

                <div style={{
					position:'relative'
				}}>
					<div style={{
						width:500,
						top:0,
						height:60,
						...props.right?{right:0}:{left:0},
						position:'fixed',
						zIndex:112,
						background:"var(--white-color)",
						display:'flex',
						borderBottom:'1px solid var(--border-color)'
					}}>
						<img alt="Close Button" onClick={()=>{
							props.close();
							clearInterval(interval);
						}} src={require('./close.png')} style={{
							margin:22,
							cursor:'pointer',
							width:16,
							height:16
						}}/>
						<div style={{flex:2}}>
							<p style={{
								marginTop:14,
								fontWeight:600,
								fontSize:14,
								height:18,
								overflowY:'hidden'
							}}>{props.title}</p>
							<p style={{
								fontWeight:400,
								fontSize:10,
								color:'var(--light-color)'
							}}>{props.des}</p>
						</div>
						{props.status==='CLOSED'?null:<ReadOnly><div style={{flex:1}}>
							<p onClick={()=>{
								props.onClose(props.id)
							}} style={{
								margin:18,
								color:"var(--primary-color)",
								fontWeight:700,
								cursor:'pointer',

							}}>
								Mark Closed
							</p>
						</div></ReadOnly>}
					</div>
                    <div style={{height:'90vh',padding:10,paddingTop:70,paddingBottom:100,overflowY:'auto'}}>
                            {rooms.map(mapItem)}
                    </div>
                    {props.closed?null:<div className="sendMessage" style={{
						height: 70,
						display: 'flex',
						padding: 12,
						backgroundColor: "var(--white-color)",
						borderTop: '1px solid var(--border-color)',
                        position:'fixed',
                        ...props.right?{right:0}:{left:0},
                        bottom:0,
                        width:500
					}}>
						<input style={{
							flex: 1,
							border: '1px solid var(--border-color)',
							borderRadius: 6,
							height: 45,
							padding: "8px 14px",
							fontSize: 15,
							color: "var(--text-color)"
						}} ref={MESSAGE} onKeyPress={handleKeyPress}
							   placeholder="Enter your messsage here" type="text"/>

					  <div style={{
                        height:45,
                        marginLeft:10,
                        backgroundColor: '#dedede',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        width:50,
                        marginTop:5,
                        borderRadius:4,
                        border:'1px solid var(--border-color)'
                    }} onClick={()=>{
                        setModal(true)
                    }}>
                        <img src={attachment} alt="" style={{
                             height:30,
                             cursor:'pointer',
                             borderRadius:4,
                             padding:5
                        }} />
                    </div>	

					</div>}
                </div>
                
            </div>

			<Modal
                show={modal}
                title="Add Attachment"
                desc="Please select the attachment you want to select"
                close={() => {
                    setModal(false);
                }}
                style={{
                    save: {
                        height: 45,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 600,
                       
                    }
                }}
                stateLoading={stateLoading}
                buttonName="Upload Attachment"
                onSave={_onChange}
            >
                <div style={{
                    border: '1px solid var(--border-color)',
                    padding: '10px 20px',
                    marginBottom: 20,
                    marginTop: 20,
                    borderRadius: 4
                }}>

                    <input
                        type="file"
                        onChange={() => { }}
                        ref={ref => file_ref = ref}
                        accept=".jpg,.jpeg,.pdf,.png,.xlsx,.csv"

                    />
                </div>
            </Modal>
           

        </div>
    </Portal>
    )
}

Index.defaultProps = {
    closed:false
}



export default Index;