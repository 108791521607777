import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {get, post} from '../../../../../../Model/Network/Config/Axios';
import {HalfModal} from '../../../../../Common/fModal/HalfModal';
import {showToast} from '../../../../../Common/fToast/ToastCallback';
import {Modal} from '../../../../../Common/fModal/Modal';
import {Editor as TextEditor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {Title as Header} from "../../../../../Header/TopHeader";
import { ReadOnly } from '../../../../../Common/fCard/ButtonOutline/ReadOnly';


const Index = (props) => {


    const [conditions, setConditions] = useState([]);
    const [addCondition, setAddCondition] = useState(false);
    const [selected, setSelected] = useState(props.conditions.length > 0 ? props.conditions.map(val => val.id) : []);
    const [edit, setEdit] = useState(false);
    const [editor, setEditor] = useState(EditorState.createEmpty());

    let getId = () => {
        return +props.id;
    }

    useEffect(() => {
        init();
    }, [])


    let init = () => {
        get(`vendor/po/requests/${getId()}/payments`, (e, r) => {
            if (r) {
                setConditions(r.conditions);
            }
        })
    }

    let edits = () => {
        let content = convertToRaw(editor.getCurrentContent());
        content = draftToHtml(content)
        content = content.toString().replace("font-family", "")
        post(`vendor/po/requests/${getId()}/${edit.id}/payments.update`, {value: content}, (e, r) => {
            if (r) {
                props.init();
                showToast({
                    type: 'success',
                    message: "Condition Update Successfully"
                })
                setEdit(false);
            }
        })
    }

    let getStatus = () => {
        return props.data.status;
    }


    let mapCondition = (item, index) => {
        let active = _.includes(selected, item.id);
        return (
            <div key={index} style={{
                minHeight: 80,
                borderRadius: 2,
                marginBottom: 20,
                padding: 20,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                border: '1px dashed var(--border-color)',
                justifyContent: 'space-between'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}
                     onClick={() => {
                         let select = selected;

                         let active = _.includes(select, item.id);

                         if (active) {
                             select = select.filter(val => val !== item.id);
                             removeCondition(item);
                         } else {
                             select.push(item.id)
                             addConditions(item);
                         }
                         setSelected(select);

                     }}>
                    <div style={{
                        
                         minHeight: 30,
                         minWidth: 30,
                        border: '1px solid #000',
                        borderRadius:4,
                        ...active ?
                            {
                                backgroundColor: 'var(--primary-color)',
                            } : {
                                backgroundColor: "var(--white-color)"
                            }
                    }}>

                    </div>
                    <div style={{marginLeft: 80,}} dangerouslySetInnerHTML={{__html: `${item.content}`}}/>
                </div>

            </div>
        )
    }


    let removeCondition = (item) => {
        post(`vendor/po/requests/${getId()}/${item.id}/payments.remove`, {}, (e, r) => {
            if (r) {
                init();
                props.init();
                showToast({
                    type: 'success',
                    message: 'Condition removed succesfully'
                })

            }
        })

    }


    let addConditions = (item) => {
        post(`vendor/po/requests/${getId()}/${item.id}/payments`, {}, (e, r) => {
            if (r) {
                init();
                props.init();
                showToast({
                    type: 'success',
                    message: 'Condition added succesfully'
                })

            }
        })
    }

    let showCondition = (item, index) => {
        return (
            <div key={index} style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 5,
            }}>
                <div style={{
                    height: 10,
                    width: 10,
                    backgroundColor: "var(--text-color)",
                    marginRight: 10,
                    borderRadius: '50%',

                }}/>

                <div dangerouslySetInnerHTML={{__html: `${item.content}`}} style={{
                    color: 'var(--text-color)',
                    fontWeight: 500
                }}/>
                {
                    getStatus() === 'DRAFT' || getStatus()==='ADMINTOUCH' ?
                        <div onClick={(e) => {
                            e.preventDefault()
                            const html = item.content;
                            const contentBlock = htmlToDraft(html);
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editor = EditorState.createWithContent(contentState);
                            setEdit(item);
                            setEditor(editor);

                        }} className="penciledit"
                             style={{
                                 marginLeft: '10px',
                                 minHeight: 18,
                                 minWidth: 18,
                                 cursor: 'pointer',
                                 position: 'relative',
                                 zIndex: 3
                             }}/>
                        : null
                }
            </div>
        )
    }

    return (
        <div>
           <div style={{padding:'0px 20px'}}>
               <Header container={true} id="terms" title="Payment Conditions"
                       des="All Payment Conditions are visible below.">
                   {
                       getStatus() === 'DRAFT' || getStatus()==='ADMINTOUCH'  ?
                           <div style={{
                               position: 'absolute',
                               right: 12,
                               top: 20
                           }}>
                           <ReadOnly>
                            <p onClick={() => {
                                    setAddCondition(true);
                                }} style={{
                                    color: "var(--primary-color)",
                                    cursor: 'pointer',
                                    fontWeight: 600,
                                    fontSize: 14
                                }}>
                                        + Add Condition
                                </p>
                           </ReadOnly>
                           </div> : null
                   }
               </Header>
           </div>
            <div className="input-container">
                {
                    props.conditions.length > 0 ?
                        props.conditions.map(showCondition) :
                        getStatus() !== 'DRAFT' ?
                            <p>No Condition Found</p> : null
                }
            </div>
            {
                addCondition ?
                    <HalfModal
                        title="Select Payment Conditions"
                        container={{
                            left:'30%'
                        }}
                        desc="Select condition to add in po"
                        close={() => {
                            setAddCondition(false);
                            init();
                        }}
                    >
                        {
                            conditions.map(mapCondition)
                        }
                    </HalfModal>
                    : null
            }
            {
                edit ?
                    <Modal
                        show={edit}
                        title="Edit Condition"
                        des="Please fill up the details and add condition"
                        close={() => {
                            setEdit(false);
                        }}
                        hideButton={true}
                        onSave={() => {
                        }}>

                        <p style={{
                            fontSize: 14,
                            fontWeight: 600,
                            marginBottom: 5
                        }}>Content :</p>
                        <div style={{
                            minHeight: 250,
                            border: '1px solid var(--border-color)',
                            padding: 10
                        }}>
                            <TextEditor
                                toolbar={{
                                    options: ['inline', 'fontFamily', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji'],
                                    fontFamily: {
                                        options: ['Quicksand,sans-serif']
                                    }
                                }}
                                editorState={editor}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={(editor) => {
                                    setEditor(editor);
                                }}
                            />
                        </div>
                        <div className="btn btn-portlet" style={{
                            marginTop: 20,
                            minWidth: '100%'
                        }} onClick={edits}>
                            Edit
                        </div>
                    </Modal>
                    : null
            }


        </div>
    )

}

export default Index;
