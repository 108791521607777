import React, {useEffect, useState} from 'react';
import moment from 'moment';
import { numberWithCommas } from '../../../../../../Utils/NumberToComma';
import { GrnView } from './GrnView';
import {useLocation} from 'react-router-dom';

function Index(props){

	const [GRNModal, setGRNModal] = useState(undefined);
    const LOCATION = useLocation();
    const params = new URLSearchParams(LOCATION.search);
    let grn_id = params.get('grn_id');


    useEffect(()=>{
        if(grn_id) {
            props.data.grns.forEach(item=>{
                if(item.grnId===grn_id) {
                    setGRNModal(item);
                }
            })
        }
    },[grn_id])


    
    return (
        <>
        <div style={{padding: '20px 40px', borderRadius: '6px', marginTop: '20px'}}>
        <div style={{fontSize: '14px', fontWeight: 'bold'}}>GRNs</div>
           <table style={{border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop: '20px', fontSize: '12px', width: '100%'}}>
               <tr style={{background: 'var(--lite-color)', textAlign: 'left'}}>
                   <th style={{padding: '15px'}}>GRN ID</th>
                   <th style={{padding: '15px'}}>Date</th>
                   <th style={{padding: '15px'}}>Invoice ID</th>
                   <th style={{padding: '15px'}}>Status</th>
                   <th style={{padding: '15px'}}>Invoice Amount</th>
                   <th>Action</th>
               </tr>
               {props.data.grns && props.data.grns.length?
                   props.data.grns.map(row=>{
                       return (
                           <tr style={{background: '#fff'}}>
                               <td style={{padding: '15px'}}>
                                   <b>{row.grnId}</b>
                               </td>
                               <td style={{padding: '15px'}}>{moment(row.date).format('DD-MM-YYYY')}</td>
                               <td style={{padding: '15px'}}>{row.invoiceId?row.invoiceId:'-'}</td>
                               <td style={{padding: '15px'}}>
                                <div style={{background: row.status === 'COMPLETED' ? 
                                'var(--green-color)':
                                 row.status === 'CANCELLED' || row.status === 'DECLINED' ? 
                                'var(--red-color)': 
                                 '#6f42c1',
                                 padding:3,
                                 borderRadius:6,
                                 color:'#fff'
                                 }}>
                                {row.status}
                                </div>
                               </td>
                               <td style={{padding: '15px'}}>{row.currency} {row.invoiceAmount?numberWithCommas(row.invoiceAmount):'-'}</td>
                               <td onClick={()=>setGRNModal(row)} style={{fontWeight: 'bold', padding: '15px', cursor: 'pointer', color: 'var(--primary-color)'}}>
                                    <svg style={{marginRight: '10px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                                    </svg>
                                   OPEN
                                </td>
                           </tr>
                       )
                   }):
                   <tr style={{background: '#fff', textAlign: 'center'}}>
                        <td style={{padding: '15px'}} colSpan={4}>
                            No GRNs
                        </td>
                    </tr>

               }
           </table>
        </div>
        {
                GRNModal ?
                    <GrnView
                        selectedGrnId={GRNModal.id}
                        viewGrn={GRNModal}
                        onClose={() => setGRNModal(undefined)}
                        {...props}
                    />
                    : <></>
        }
        </>
    )
}

export default Index;