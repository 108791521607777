import React from 'react';
import {Link} from 'react-router-dom'
import Search from './search.png'
import {connect} from 'react-redux'
import {get} from "../../Model/Network/Config/Axios";
import { debounce } from '../../Utils/debounce';


class Header extends React.Component {


	state = {
		open: false,
		current: undefined,
		count: 0,
		items: [],
	}


	_handleKeyDown = (event) => {
		var ESCAPE_KEY = 27;
		switch (event.keyCode) {
			case ESCAPE_KEY:
				if (this.state.open) {
					this.setState({
						...this.state
						, open: !this.state.open
					})
				}
				break;
			default:
				break;
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown);
	}


	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown);
	}

	render() {
		let config = this.props.config
		return (
			<div id="header" style={{paddingTop: 10}}>
				<div className="flex-1" style={{
					display: 'flex',
					flexDirection: 'row-reverse',
					alignItems: 'center'
				}}>
					<Link to={'/app/settings'} style={{width: 120}}>
						<div>
							<p style={{fontSize: 15, fontWeight: 600, color: 'var(--text-color)'}}>
								{config ? config.name : "Admin"}
							</p>
							<p style={{
								fontSize: 13,
								fontWeight: 400,
								color: 'var(--light-color)',
								maxWidth: 150,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}>
								{config ? config.companyName : ""}
							</p>
						</div>
					</Link>
					<div style={{position: 'relative'}}>
						<div style={this.state.items.length > 0 ? {
							boxShadow: "2px 3px 3	px rgba(0, 0, 0, .1)",
							background:"var(--white-color)",
							border: "1px solid var(--border-color)"
						} : {}} className="search-container">
							<input ref={ref => this._search = ref} onChange={debounce(event => {
								let value = this._search.value;
								if (value && value.length > 2) {
									let data = {}
									get('/search', (e, r) => {
										if (r) {
											this.setState({
												...this.state,
												...r
											})
										}
									}, {
										q: value,
										...data
									})
								} else {
									this.setState({
										...this.state,
										items: []
									})
								}
							})} type="text" placeholder="Enter search criteria..."/>
							<img onClick={() => {
								this.setState({
									...this.state
									, open: !this.state.open,
									items: []
								})
							}}
								 style={{
									 width: 16,
									 height: 16,
									 position: 'absolute',
									 left: 20,
									 top: 16,
									 cursor: 'pointer'
								 }} src={Search}
								 alt="fullscreen"/>
							{this.state.items.length > 0 ? <div style={{
								paddingTop: 4,
								paddingBottom: 8,
							}} className="cont">
								{
									this.state.items.map(item => {
										let arr=item.path.split("/");
										if(arr&& arr.length>3)
										{
											if(arr[2]==='trips')
											{
												let new_path=`/app/travel/trips/details/${arr[3]}`;
												item.path=new_path;
											}
										}
										return (
											<Link to={item.path} key={item.path} onClick={() => {
												this.setState({
													...this.state
													, open: !this.state.open,
													items: []
												})
											}} target="_blank">
												<div style={{
													position: 'relative',
													paddingLeft: 14,
													paddingRight: 14,
													paddingTop: 4,
													paddingBottom: 4,
													cursor: 'pointer',
													overflow: 'hidden'
												}}>
													<span style={{
														position: 'absolute',
														right: 10,
														top: 10,
														fontWeight: 600,
														fontSize: 7,
														color: "var(--primary-color)"
													}}>
														{item.type}
													</span>
													<p style={{
														fontWeight: 600,
														color: 'var(--text-color)',
														fontSize: 13
													}}>{item.title}</p>
													<p style={{
														fontWeight: 400,
														width: '75%',
														overflow: 'hidden',
														height: 14,
														color: 'var(--light-color)',
														fontSize: 9
													}}>{item.path}</p>
												</div>
											</Link>
										)
									})
								}
							</div> : null}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(state => ({
	config: state.auth.config
}))(Header);
