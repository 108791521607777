import React from 'react';
import {get} from '../../../../Model/Network/Config/Axios';
import {SearchableSelect} from '../../Class/Select/SearchableSelec2';
import {useState,useEffect} from 'react'

function NormalTds(props) {
    let [all,setAll] = useState([]);
    let [tds,setTds] = useState([]);
    let [selected,setSelected] = useState(false);
    useEffect(()=>{
        get(`/vendor/config/tds/all`, (e, r) => {
            if (r) {
                let tds = r.tds.map(val => ({
                    id: val.id,
                    label: val.code,
                    des: val.description,
                    tax: val.tax
                }))
                setTds(tds)
            }
        })

    },[])
    useEffect(()=>{
        if(props.handleRef){
            props.handleRef({
                getValue:()=>selected
            })
        }
    },[selected])
    return (
                
            <SearchableSelect
                    label="Select Tds"
                    placeholder="Select Tds"
                    onChange={val => {
                        if (val.length > 0) {
                            let allItems = tds.filter(item => {
                                if (val.toLowerCase().includes(item.label)) {
                                    return true
                                }
                                if (val.toLowerCase().includes(item.des)) {
                                    return true;
                                }
                                if (val.toLowerCase().includes(item.tax)) {
                                    return true
                                }
                                return false;
                            })
                            setAll(allItems)
                        } else {
                           setAll([])
                        }
                    }}
                    select={selectedTds => {
                       setSelected(selectedTds)
                    }}
                    value={selected}
                    items={all.length ? all : tds}
                />
    )
}

export { NormalTds };
