import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Store from '../Redux'
import {Provider} from 'react-redux';
import Login from './Login'
import Authorize from './Authorize'
import ResetPassword from './ResetPassword'
import Redirect from './Redirector'
import '../Components/Common/index.css'
import '../Components/Common/procure.css'
import '../Components/Common/theme.css'
import App from './AuthenticatedController';
import {positions, Provider as ToastProvider} from "react-alert";
import AlertTemplate from 'react-alert-template-basic'

const ReduxStore = Store();

const Index = () => {
	return (
		<ToastProvider template={AlertTemplate} {...{
			timeout: 5000,
			position: positions.TOP_CENTER,
			containerStyle: {
				zIndex: 1111
			}
		}}>
			<Provider store={ReduxStore}>
				<BrowserRouter>
					<Switch>
						<Route exact={true} path="/" component={Login}/>
						<Route exact={true} path="/authorize" component={Authorize}/>
						<Route exact={true} path="/login" component={ResetPassword}/>
						<Route path="/dashboard" component={Redirect}/>
						<Route path="/app" component={App}/>
					</Switch>
				</BrowserRouter>
			</Provider>
		</ToastProvider>
	)
}

export default Index();
