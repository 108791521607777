import React, { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import {useParams} from  'react-router-dom';
import {get} from '../../../../../Model/Network/Config/Axios';
import {PageLoader} from '../../../../Common/Components/PageLoader';
import {handleError} from "../../../../Common/fToast/ToastCallback";
import RenderPoDetails from './RenderPoDetails';
import ToggleSwitch from '../../../../Common/fSwitch';
import PoAmend from '../fPoAmend';
import { Modal } from '../../../../Common/fModal/Modal';


const PoDetails = (props) => {

	const [loading,setLoading] = useState(true);
	const [details,setDetails] = useState({});
	const [invoices,setInvoices] = useState([]);
	const [conditions, setConditions] = useState([]);
	const [toggle, setToggle] = useState(false);
	const [modal, setModal] = useState(false);
	const [defaultBank, setDefaultBank] = useState(undefined);
	const {id} = useParams();
	let {poAmendmentRequest} = useSelector(state=>state.auth.config.plugins);

	useEffect(()=>{
		init();
	},[])

	let init = () => {
		get(`vendor/po/requests/${id}/details`, (e, r) => {
			if (r) {
				setDetails(r);
				setInvoices(r.invoices)
				fetchDefaultBankDetails(r.vendor.id)
				fetchConditions();
			}
			else if(e){
				handleError(e);
			}
		})
	}

	let fetchDefaultBankDetails=(id)=>{
		get(`/vendor/contacts/${id}/banks`, (e, r) => {
            if (r) {
                setDefaultBank(r.defaultBank)
            }
        })
	}

	let fetchConditions=()=>{
		get(`vendor/po/requests/${id}/conditions`, (e, r) => {
            if (r) {
                setConditions(r.conditions);
				setLoading(false);
            }
        })
	}



	if(loading) {
		return(<PageLoader />)
	}
	
	return (
		<>
			{poAmendmentRequest ?
				<div style={{display:'flex',width:'66%',marginLeft:28,paddingBottom:8}}>
				<ToggleSwitch
					style={{background:'#fff',padding:10,borderRadius:10,marginLeft:5 }}
					label="PO Amendment Request"
					status={toggle}
					callback={() => { 
						toggle ? setModal(true) : setToggle(!toggle) 
					}}
				/>
			    </div>
				: null}
			{toggle ? 
			   <PoAmend
				details={details}
				invoices={invoices}
				conditions={conditions}
				init={() => init()}
				{...props}
			/> : 
			   <RenderPoDetails
				defaultBank={defaultBank}
				details={details}
				invoices={invoices}
				conditions={conditions}
				init={() => init()}
				{...props}
			/>}
			{modal &&
				<Modal
					title={'Confirmation'}
					buttonName={'Yes'}
					des="Please confirm if you want to revert the changes, as doing so will clear all modifications made."
					close={() => setModal(false)}
					onSave={() => {
						setToggle(false)
						setModal(false)
					}}
					show={true}
				>
				</Modal>
			}
		</>
		)

}



export default PoDetails
