import React from 'react'
import {Modal} from "../../fModal/Modal";
import {get} from "../../../../Model/Network/Config/Axios";
import {SearchableSelect} from "../../Class/Select/SearchableSelec2";
import {FloatInput} from '../../Class/FloatLabelInput';

class SearchVendor extends React.Component {


	state = {
		modal: undefined,
		allVendor: [],
		allGstins:[],
		vendor:undefined
	}

 componentDidMount(){
	if(this.props.Vendor) {
		this.setState({
			...this.state,
			vendor:this.props.Vendor,
		})
	}
  }

	loadGstins = (item) => {
		get(`/vendor/contacts/${item.value}/details`, (e, r) => {
			if (r) {
				this.setState({
					...this.state,
					allGstins: r.addresses
				})
			}
		})
	}

	componentDidUpdate(prevProps) {
		if(this.props.value !== prevProps.value && !this.props.modal) {
			this.setState({
				...this.state,
				vendor:this.props.value
			})

		}
	}



	render() {
		if(!this.props.modal){
			return  (
				<div style={{position:'relative'}}>
					<SearchableSelect
					 disabled={this.props.disabled}
					 label={this.state.select && !this.props.disabledLabel?
					  <div style={{textOverflow:'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{this.state.select.address}</div>
					  :'Select Vendor'
					}
					 placeholder="Select Vendor"
						value={this.state.vendor}
						select={item => {
							this.setState({
								...this.state,
								vendor: item,
								allGstins:[],
								select: undefined
							})
							this.loadGstins(item)
						}}
						onChange={val => {
							let mData={
								q: val
							}
							if(this.props.entityId){
								mData['entityId']=this.props.entityId
							}
							// if (val.length > 2) {
								get(`/vendor/contacts/search`, (e, r) => {
									this.setState({
										...this.state,
										allVendor: r.contacts.map(val => ({
											label: val.title,
											value: val.id,
											currency:val.currency
										}))
									})
								},mData)

							// }
						}}
						items={this.state.allVendor}
						style={{
							color: '#e6ebf3',
							height: 46,
							marginBottom: 12
						}}
					/>

					{
						this.state.allGstins.length>0 && !this.state.select?
							<div style={{position: 'absolute', top:'80px', right: 0, width: '100%', maxHeight: '300px', border: '1px solid var(--border-color)', background: 'var(--white-color)', overflowY: 'scroll', borderRadius: '0px 0px 10px 10px', boxShadow: '0px 6px 4px rgba(0,0,0,0.1)', padding: '15px', zIndex: '51'}}>
								<div style={{fontSize:11,fontWeight: 'bold'}}>
									Select Address
								</div>
								{
									this.state.allGstins.map((item,index)=>{
										return(
											<div key={index} onClick={()=>{
												this.props.onSelect({
													id : this.state.vendor.value,
													name:this.state.vendor.label,
													label: this.state.vendor.label,
													currency:this.state.vendor.currency,
													address:item.address,
													city:item.city,
													gstin:item.gstin,
													state:item.state,
													key:item.id,
												})
												this.setState(prev => ({
													...prev,
													select:{
														id : this.state.vendor.value,
														name:this.state.vendor.label,
														currency:this.state.vendor.currency,
														address:item.address,
														city:item.city,
														gstin:item.gstin,
														state:item.state,
														email:this.state.vendor.email,
														key:item.id
													},
													modal:false,
													allVendors:[]
												}))
											}} className='background-on-hover' style={{padding: '15px', marginTop: '10px', fontSize: '11px', background: 'var(--lite-color)', borderRadius: '5px', cursor: 'pointer'}}>
													<div style={{fontSize:12, fontWeight:600}}>{item.title}</div>
													<div>{item.address}</div>
													<div>{item.city}, {item.state}</div>
													<div>{item.gstin}</div>
											</div>
										)
									})
								}
							</div>
							:undefined
					}
				</div>
			)
		}
		return (
			<div>
               <div style={{cursor:'pointer'}}>
				   <FloatInput
					   type="text"
					   title="Search Vendor"
					   handleRef={ref=>this.vendor=ref}
					   disabled={true}
					   onClick={()=>{
						   this.setState({
							   ...this.state,
							   modal:true
						   })
					   }}
				   />
			   </div>
				{
					this.state.modal ?
						<Modal show={true}
							   onSave={() => {
							   }}
							   title="Select Vendor"
							   des="Please select a vendor to continue."
							   close={() => {
								   this.setState({
									   ...this.state,
									   modal: false
								   })
							   }}
							   hideButton={true}
						>
							<SearchableSelect label="Select Vendor" placeholder="Select Vendor"
											  value={this.state.vendor}
											  select={item => {
												  this.setState({
													  ...this.state,
													  vendor: item,
													  allGstins:[]
												  })
												  this.loadGstins(item)
											  }}
											  onChange={val => {
												let mData={
													q: val
												}
												if(this.props.entityId){
													mData['entityId']=this.props.entityId
												}
												  if (val.length > 2) {
													  get(`/vendor/contacts/search`, (e, r) => {
														  this.setState({
															  ...this.state,
															  allVendor: r.contacts.map(val => ({
																  label: val.title,
																  value: val.id
															  }))
														  })
													  }, mData)

												  }
											  }}
											  items={this.state.allVendor}
											  style={{
												  color: '#e6ebf3',
												  height: 46,
												  marginBottom: 12
											  }}
							/>
							{
								this.state.allGstins.length>0?
									<div>
										<p style={{fontSize:11,color:'var(--light-color)',marginTop:8}}>
											All Addresses
										</p>
										{
											this.state.allGstins.map(item=>{
												return(
													<div style={{position:'relative',marginTop:8}}>
														<p style={{fontSize:15, fontWeight:600,color:"var(--text-color)"}}>
															{item.title}
														</p>
														<p style={{fontSize:11,color:"var(--light-color)"}}>
															{item.address}
														</p>
														<p style={{fontSize:11,color:"var(--light-color)"}}>
															{item.city}, {item.state}
														</p>
														<p style={{fontSize:11,color:"var(--light-color)"}}>
															{item.gstin}
														</p>
														<p onClick={()=>{
															this.props.onSelect({
																id : this.state.vendor.value,
																name:this.state.vendor.label,
																address:item.address,
																city:item.city,
																gstin:item.gstin,
																state:item.state,
																key:item.id
															})
                                                            this.vendor.setValue(this.state.vendor.label);
															this.setState({
																...this.state,
																select:{
																	id : this.state.vendor.value,
																	name:this.state.vendor.label,
																	address:item.address,
																	city:item.city,
																	gstin:item.gstin,
																	state:item.state,
																	email:this.state.vendor.email,
																	key:item.id
																},
																modal:false,
																allGstins:[],
																allVendors:[]
															})
														}} style={{
															position:'absolute',
															right:0,
															top:12,
															width:80,
															height:24,
															color:"var(--white-color)",
															background:'var(--primary-color)',
															cursor:'pointer',
															borderRadius:2,
															padding:5,
															fontWeight:600,
															fontSize:11,
															textAlign:'center'
														}}>
															Select
														</p>
													</div>
												)
											})
										}
									</div>:undefined
							}
						</Modal> : undefined
				}
			</div>
		)
	}

}

SearchVendor.defaultProps = {
	disabled:false
}

export default SearchVendor
